.header-white{
    background-image: url("../img/Backgound_White.png");
    background-size: cover;
    align-items: flex-end;
    padding-top: 8px;
}

.offset-white{
    margin-left: 21%;
}

.col-nest-header{
    margin-top: 24px;
}

.icon-img{
    margin-right: 18px;
}
.margin-text-header{
    margin-top: 4px;
}
.row-header{
    padding-top: 8px;
}
@media only screen and (max-width: 1000px) {
    .offset-white{
        margin-left: 0%;

    }

    .header-white{
        background-color: white;
        align-items: flex-end;
        padding-top: 8px;
    }

    .margin-text-header{
        padding: 12px;
    }
    .mobile-padding-header{
        padding-left: 24px !important;
    }
    .col-nest-header{
        
    }
    
  }

