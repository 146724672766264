.background-wave {
    background-image: url("/Users/michal/Documents/Projekty/WWW/NestReact/nest/src/components/img/Background.svg");
    background-size: cover;
    background-repeat-y: no-repeat;
    height: 70vh;
    display: flex;
}

.container-flex{
    display: flex;
    flex-direction: column-reverse;
}

