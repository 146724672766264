.nav-link{
    color: #4D3975 !important;
    font-family: 'Lato';
    font-style: normal;
    padding: 8px 16px !important;
}
.nav-link:hover{
    color: #8764cb !important;
}

.Nav-logo
{
    width: auto;
    height: 46px;
    margin-left: 27px;

}


.navbar{
    padding-top: 0px !important;
    padding-bottom: 4px !important;
}
.icon-img {
min-width: 25px;
}
