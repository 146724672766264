.section-information{
    padding-top: 64px;
    padding-bottom: 64px;
}

@media only screen and (max-width: 600px) {
    .section-information{
        padding-top: 32px;
        padding-bottom: 32px;
    }
  }