body {
  margin: 0;
  font-family: 'Lato';
  font-style: normal;
  zoverflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container__sectionInfo{
  background-color: white;
}

.container__index{
padding: 0px !important;
}