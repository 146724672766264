.boxNews{
    border: 1px solid #ccc!important;
    border-radius: 16px;
    background-color: #f8f7f8;
    box-shadow: 4px 5px 9px 0px rgb(72 2 93 / 17%);
    transition: transform .2s;
}

.boxNews:hover{
    transform: scale(1.02);
}

.boxNews__Img{
    border: 1px solid #ccc!important;
    border-radius: 16px;
    background-color: #f8f7f8;
}



.boxNews__InformationTitle{
   color: #b0b3b8;
   font-size: .8125rem;
}
.boxNews__Images{
    max-height: 400px;
    width: auto;
}

.news__Logo{
    height: 22px;
    margin-left: 0px;
    margin-right: 5px;
    padding-bottom: 5px;
}


/* Slow-motion Zoom Container */
.img-hover-zoom--slowmo img {
    transform-origin: 50% 65%;
    transition: transform 5s, filter 3s ease-in-out;
    filter: brightness(150%);
  }
  
  /* The Transformation */
  .img-hover-zoom--slowmo:hover img {
    filter: brightness(100%);
    transform: scale(3);
  }
.w-100{
    max-height: 200px !important;
    object-fit: cover !important;
}

table {
    border-collapse: collapse;
    width: 100%;
}
th, td {
    border: 1px solid black !important;
    padding: 8px !important;
    text-align: left !important;
}
th {
    background-color: #f2f2f2;
}
